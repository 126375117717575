import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  useSocietiesSearch as useSocietiesSearchInternal,
  ReturnType as useSocietiesSearchReturnType,
} from 'hooks/useSocietiesSearch';

const initialContext: useSocietiesSearchReturnType = {
  societies: [],
  resetFilter: noop,
  search: '',
  setSearch: noop,
  fetchMoreSocieties: () => noop,
  hasMoreRecords: false,
  isLoading: false,
  selectedOptions: {},
  setSelectedOptions: noop,
  selectedLabelsRef: { current: {} },
  isLoadingMore: false,
};

const SocietiesSearchContext =
  createContext<useSocietiesSearchReturnType>(initialContext);

export const useSocietiesSearch = (): useSocietiesSearchReturnType =>
  useContext(SocietiesSearchContext);

export const SocietiesSearchProvider: React.FC = ({ children }) => {
  const value = useSocietiesSearchInternal();

  return (
    <SocietiesSearchContext.Provider value={value}>
      {children}
    </SocietiesSearchContext.Provider>
  );
};
