import { WorkingModel } from 'lib/models/job';
import { CitiesResponse } from './city';
import { CompanyResponse } from './company';
import { EventTouchpointable, Place } from './event';
import { JobTouchpointable } from './job';
import { SkillsResponse } from './skill';
import { StudentSocietyResponse } from './student-society';
import { TagResponse, TagsResponse, TagType } from './tag';
import { TouchpointType } from './touchpoint';
import { FollowingResponse } from './following';
import { MetaTagsResponse } from './meta-tag';
import { InternshipTouchpointable } from './internship';
import { CohostResponse, CohostsResponse } from './cohost';
import { QuestionsResponse, QuestionState } from './answers';
import { EventCategoriesAttributes } from './event-categories';
import { CountriesResponse } from './country';
import { JSONObject } from '.';

export enum TouchpointState {
  EXPIRED = 'expired',
  PUBLISHED = 'published',
  DRAFT = 'draft',
  SAVE = 'save',
  INVITATION_PENDING = 'Invitation pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending',
  CLOSED = 'closed',
  ENDED = 'ended',
  SOLD_OUT = 'Sold Out',
  APPROVED = 'approved',
  READY_FOR_APPROVAL = 'ready_for_approval',
}

export enum TouchpointSource {
  SOCIETY_TOOL = 'Society Tool',
  RECRUITING_PLATFORM = 'Recruiter Platform',
  HUZZLE = 'Huzzle',
}

export enum OPPORTUNITY_TYPE {
  JOB = 'job',
  PART_TIME_JOB = 'part_time',
  INTERNSHIP = 'internship',
  EVENT = 'event',
}

export interface OpportunityAttributes {
  id: string;
  title: string;
  description: string;
  cities: CitiesResponse;
  area_of_responsibilities: TagsResponse<TagType.AreaOfResponsibility>;
  education_requirements: TagsResponse<TagType.EducationRequirement>;
  apply_url: string;
  touchpointable:
    | JobTouchpointable
    | EventTouchpointable
    | InternshipTouchpointable;
  enabled: boolean;
  touchpointable_type:
    | TouchpointType.Job
    | TouchpointType.Event
    | TouchpointType.Internship;
  created_at: string;
  start_date: string;
  creatable_for: CompanyResponse | StudentSocietyResponse;
  application_deadline: string;
  slug: string;
  short_slug?: string;
  topics: TagsResponse<TagType.Topic>;
  skills: SkillsResponse;
  following: FollowingResponse | null;
  is_applied: boolean;
  is_eligible_for_auto_apply: boolean;
  meta_title: string;
  meta_description: string;
  meta_tags: MetaTagsResponse;
  posted_at: string;
  state: TouchpointState;
  expired_at: string;
  kind: string;
  sanitized_requirements: string;
  sanitized_responsibilities: string;
  sanitized_benefits: string;
  new_responsibilities: Array<string>;
  new_requirements: Array<string>;
  responsibilities: string;
  requirements: string;
  benefits: string;
  cohost_application: CohostResponse;
  cohost_applications: CohostsResponse;
  view_counter: number;
  applications: number;
  place: Place;
  is_online: boolean;
  online_link: string;
  avatar_urls: Array<string>;
  university_required?: boolean;
  study_year_required?: boolean;
  study_subject_required?: boolean;
  degree_required?: boolean;
  linkedin_profile_required?: boolean;
  cv_required?: boolean;
  university_required_state?: QuestionState;
  study_year_required_state?: QuestionState;
  study_subject_required_state?: QuestionState;
  degree_required_state?: QuestionState;
  linkedin_profile_required_state?: QuestionState;
  cv_required_state?: QuestionState;
  questions: QuestionsResponse;
  applicable_questions: QuestionsResponse;
  source: TouchpointSource | string;
  matchable_id?: string;
  rating_map: Record<string, number>;
  is_waitlisted: boolean;
  industries: TagsResponse<TagType.Industry>;
  industry: TagResponse<TagType.Industry>;
  sanitized_description?: string;
  pay_currency?: string;
  ai_summary?: Array<string>;
  application_process: Array<string>;
  compensation_details?: string;
  short_description?: string;
  featured: boolean;
  is_in_current_matches: boolean | null;
  public_url: string;
  working_model: WorkingModel | null;
  started: boolean | null;
  notify_creatable: boolean;
  show_guest_list?: boolean;
  remote_country_ids: Array<string>;
  remote_countries: CountriesResponse;
  available_member_invite_limit: number;
  available_huzzle_invite_limit: number;
  ai_faqs: string;
  processed_json_schema: JSONObject | null;
}

export interface Opportunity {
  id: string;
  type: 'touchpoint';
  attributes: OpportunityAttributes;
}

export type TOpportunity = OpportunityAttributes;

type BasicCreatableFor = {
  id: string;
  type: 'Company' | 'StudentSociety';
  name: string;
  icon_url: string;
  is_verified: boolean;
  is_huzzle_verified: boolean;
};

export type BasicOpportunity = Pick<
  OpportunityAttributes,
  | 'id'
  | 'slug'
  | 'touchpointable_type'
  | 'title'
  | 'short_slug'
  | 'public_url'
  | 'featured'
  | 'start_date'
  | 'created_at'
  | 'posted_at'
  | 'application_deadline'
  | 'is_in_current_matches'
> & { basic_creatable_for: BasicCreatableFor; icon_url: string | null };

export type BasicOpportunitiesResponse = {
  data: BasicOpportunity[];
  meta: {
    total: number;
  };
};

export interface OpportunityResponse {
  data: Opportunity;
}

export interface OpportunitiesResponse {
  data: Opportunity[];
  meta: {
    expiring_soon_count: number;
    total: number;
  };
}

export interface ManagerOpportunitiesResponse {
  data: Opportunity[];
  meta: {
    search_page?: string | null;
    total: number;
  };
}

export interface OpportunityTypesAttributes {
  id: string;
  display_name: string;
  description: string;
  touchpointable_kinds: Array<string>;
  touchpointable_kind_mapping: Record<string, number>;
  event_categories: Array<EventCategoriesAttributes>;
  type: 'opportunity_type';
  experience_levels: Record<string, Array<number>>;
}

export type OpportunityTypesResponse = Array<OpportunityTypesAttributes>;

export enum OPPORTUNITY_EXPERIENCE_LEVEL {
  ENTRY_LEVEL = 'Entry level/ Graduate',
  JUNIOR_LEVEL = 'Junior (1-2 years)',
  MID_LEVEL = 'Mid-level (3-4 years)',
  SENIOR_LEVEL = 'Senior (5-8 years)',
  EXPERT_LEVEL = 'Expert / Leadership (9+ years)',
  EXPERT_LEVEL_SHORTENED = 'Expert (9+ years)',
}
